/*
 * 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
 * */
import axios from "axios";
import { getDefaultHost } from "./pages/url/url";
const url = getDefaultHost();
export default function getMenus(userId) {
    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    // return Promise.resolve([
    //     // {key: '1', text: '多级菜单', icon: 'align-left'},
    //     // {key: '1-1', parentKey: '1', text: 'level-1-1', path: '/user-center', icon: 'align-left'},
    //     // {key: '1-2', parentKey: '1', text: 'level-1-2', icon: 'align-left'},
    //     // {key: '1-3', parentKey: '1', text: 'level-1-3', icon: 'align-left'},
    //     // {key: '1-4', parentKey: '1', text: 'level-1-4', icon: 'align-left'},
    //     // {key: '1-4-1', parentKey: '1-4', text: 'level-1-4-1', icon: 'align-left'},
    //     // {key: '1-4-2', parentKey: '1-4', text: 'level-1-4-2', icon: 'align-left'},
    //     // {key: '1-4-3', parentKey: '1-4', text: 'level-1-4-3', icon: 'align-left'},
    //     // {key: '1-4-4', parentKey: '1-4', text: 'level-1-4-4', icon: 'align-left'},
    //     // {key: '1-4-3-1', parentKey: '1-4-3', text: 'level-1-4-3-1', icon: 'align-left'},
    //     // {key: '1-4-3-2', parentKey: '1-4-3', text: 'level-1-4-3-2', icon: 'align-left'},
    //     // {key: '1-4-3-3', parentKey: '1-4-3', text: 'level-1-4-3-3', icon: 'align-left'},
    //     // {key: '1-4-3-4', parentKey: '1-4-3', text: 'level-1-4-3-4', icon: 'align-left'},
    //     // {key: 'menu', text: '菜单管理', icon: 'menu', path: '/menu-permission', order: 901},
    //     {key: 'user', text: '用户管理', icon: 'user', path: '/users', order: 900},
    //     {key: 'organ', text: '机构管理', icon: 'profile', path: '/organs', order: 899},
    //     {key: 'doctor', text: '医生管理', icon: 'idcard', path: '/doctors', order: 898},
    //     {key: 'label', text: '标签管理', icon: 'tags', path: '/labels', order: 897},
    //     {key: '1', text: '新闻管理', icon: 'appstore', order: 896},
    //     {key: '1-1',parentKey:'1', text: '基础新闻', path: '/news',icon: 'align-left'},
    //     {key: '1-2',parentKey:'1', text: '画像推送', path: '/portraits',icon: 'align-left'},
    //     {key: '2', text: '财务管理', icon: 'money-collect', order: 888},
    //     {key: '2-1',parentKey:'2', text: '用户订单', path: '/user-orders',icon: 'align-left'},
    //     {key: '2-2',parentKey:'2', text: '医生收入', path: '/doctor-income',icon: 'align-left'},
    //     {key: '3', text: '订单管理', icon: 'book', order: 888},
    //     {key: '3-1',parentKey:'3', text: '订单列表', path: '/order-list',icon: 'ordered-list'},
    //     {key: '3-2',parentKey:'3', text: '评论审批', path: '/comment-approval',icon: 'message'},
    //     {key: '4', text: '退款审核', path: '/refund-review',icon: 'red-envelope'},
    //     {key: 'strategy', text: '就诊攻略', icon: 'alert', path: '/visit-strategy', order: 895},
    //     {key: 'guidelines', text: '避坑指南', icon: 'dashboard', path: '/pit-avoidance-guidelines', order: 894},
    //     {key: 'banner', text: '轮播图管理', icon: 'menu-unfold', path: '/banners', order: 893},
    //     {key: 'pet-text', text: '宠物语句配置', icon: 'aliwangwang', path: '/pet-sentence', order: 892},
    //     {key: 'system-messages', text: '系统消息管理', icon: 'message', path: '/system-messages', order: 891},
    //     {key: 'search-statement', text: '搜索语句配置', icon: 'search', path: '/search-statement', order: 890},
    //     {key: 'account-management', text: '账号管理', icon: 'account-book', path: '/account-management', order: 900},
    // ]);
    return new Promise((resolve, reject) => {
        axios.post(url + "/account/getMenu",{system:1}).then((res) => {
            const meunlist = [...res.data.data.menuList];
            meunlist.forEach((element) => {
                element.key = element.key + "";
                element.parentKey = element.parentKey + "";
            });
            if (res.data.status === 0) {
                resolve(meunlist);
            } else {
                reject(res.data);
            }
        });
    });
}
