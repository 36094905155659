// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login1',
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/doctors',
    '/login1',
    '/login',
    '/organs',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/account-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/account-management/index.jsx'),
    },
    {
        path: '/ai_training_testing',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/ai-training-testing/index.jsx'),
    },
    {
        path: '/banners',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/banners/index.jsx'),
    },
    {
        path: '/comment-approval',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/comment-approval/index.jsx'),
    },
    {
        path: '/coupon-configuration',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/coupon-configuration/index.jsx'),
    },
    {
        path: '/coupon-list',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/coupon-list/index.jsx'),
    },
    {
        path: '/cutepet-test',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/cutepet-test/index.jsx'),
    },
    {
        path: '/doctor-income',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/doctor-income/index.jsx'),
    },
    {
        path: '/doctor-grade',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/doctors/doctor-grade/index.jsx'),
    },
    {
        path: '/doctors/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/doctors/Edit.jsx'),
    },
    {
        path: '/doctors',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/doctors/index.jsx'),
    },
    {
        path: '/emergency-time',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/emergency-time/index.jsx'),
    },
    {
        path: '/example/ajax',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/free-users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/free-users/index.jsx'),
    },
    {
        path: '/fullcycle-petownership',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/fullcycle-petownership/index.jsx'),
    },
    {
        path: '/health-plan',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/health-plan/index.jsx'),
    },
    {
        path: '/',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/iframe/index.jsx'),
    },
    {
        path: '/labels',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/labels/index.jsx'),
    },
    {
        path: '/login1',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/login/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/login1/index.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/message-users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/message-users/index.jsx'),
    },
    {
        path: '/news',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/news/index.jsx'),
    },
    {
        path: '/night-shift-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/night-shift-management/index.jsx'),
    },
    {
        path: '/order-list',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/order-list/index.jsx'),
    },
    {
        path: '/organs-update-review/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/organs-update-review/Edit.jsx'),
    },
    {
        path: '/organs-update-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/organs-update-review/index.jsx'),
    },
    {
        path: '/organs/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/organs/Edit.jsx'),
    },
    {
        path: '/organs',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/organs/index.jsx'),
    },
    {
        path: '/other-configurations',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/other-configurations/index.jsx'),
    },
    {
        path: '/package-income',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-incom/index.jsx'),
    },
    {
        path: '/package-comment-approval',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/comment-approval/index.jsx'),
    },
    {
        path: '/package-list',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/package-list/index.jsx'),
    },
    {
        path: '/package-order-list',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/package-order-list/index.jsx'),
    },
    {
        path: '/package-update-review/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/package-update-review/Edit.jsx'),
    },
    {
        path: '/package-update-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/package-update-review/index.jsx'),
    },
    {
        path: '/package-refund-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/refund-review/index.jsx'),
    },
    {
        path: '/type-managerment',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/package-management/type-management/index.jsx'),
    },
    {
        path: '/pet-profile-testing',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/pet-profile-testing/index.jsx'),
    },
    {
        path: '/pet-sentence',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/pet-sentence/index.jsx'),
    },
    {
        path: '/pit-avoidance-guidelines',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/pit-avoidance-guidelines/index.jsx'),
    },
    {
        path: '/portraits',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/portrait/index.jsx'),
    },
    {
        path: '/private-qrcode',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/private-qrcode/index.jsx'),
    },
    {
        path: '/question-bank-settings/_/question_bank_edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/question-bank-settings/detail.jsx'),
    },
    {
        path: '/question-bank-settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/question-bank-settings/index.jsx'),
    },
    {
        path: '/question-settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/question-settings/index.jsx'),
    },
    {
        path: '/refund-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/refund-review/index.jsx'),
    },
    {
        path: '/search-statement',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/search-statement/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/setting/index.jsx'),
    },
    {
        path: '/system-messages',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/system-messages/index.jsx'),
    },
    {
        path: '/system-setting',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/system-setting/index.jsx'),
    },
    {
        path: '/unread-time',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/unread-time/index.jsx'),
    },
    {
        path: '/update-review/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/update-review/Edit.jsx'),
    },
    {
        path: '/update-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/update-review/index.jsx'),
    },
    {
        path: '/urban-assistance',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/urban-assistance/index.jsx'),
    },
    {
        path: '/user_orders/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/user-orders/Edit.jsx'),
    },
    {
        path: '/user-orders',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/user-orders/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/users/Edit.jsx'),
    },
    {
        path: '/users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/users/index.jsx'),
    },
    {
        path: '/visit-strategy',
        component: () => import('E:/uni星球/unifamily-new/unifamily/manager/src/pages/visit-strategy/index.jsx'),
    },
];
