import COS from "cos-js-sdk-v5";
import { ajax } from "./ajax";

class cosService {
    constructor() {}

    cos = null;
    bucket = "";
    region = "";

    init = (bucket, region) => {
        this.bucket = bucket;
        this.region = region;
        this.cos = new COS({
            getAuthorization: (options, callback) => {
                ajax.get(`/ClientToken/CloudPicToken`).then((res) => {
                    if (res.Data) {
                        const data = JSON.parse(res.Data);
                        const credentials = data && data.Credentials;
                        if (!data || !credentials)
                            return console.error("credentials invalid");
                        callback({
                            TmpSecretId: credentials.TmpSecretId,
                            TmpSecretKey: credentials.TmpSecretKey,
                            XCosSecurityToken: credentials.Token,
                            ExpiredTime: data.ExpiredTime,
                        });
                    }
                });
            },
        });
    };

    putObject = async (key, fileObject) => {
        return new Promise((resolve, reject) => {
            this.cos.putObject(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Key: key,
                    StorageClass: "STANDARD",
                    Body: fileObject,
                },
                (err, data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };

    getBucket = async (prefix) => {
        return new Promise((resolve, reject) => {
            this.cos.getBucket(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Prefix: prefix,
                },
                (err, data) => {
                    if (data.Contents) {
                        resolve(data.Contents);
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };

    getObjectUrl = async (key) => {
        return new Promise((resolve, reject) => {
            this.cos.getObjectUrl(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Key: key,
                    Sign: true,
                    Expires: 3600,
                },
                (err, data) => {
                    if (data.Url) {
                        resolve(data.Url);
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };

    getPublicUrl = (key) => {
        return `https://${this.bucket}.cos.${this.region}.myqcloud.com/${key}`;
    };

    getObject = async (key) => {
        return new Promise((resolve, reject) => {
            this.cos.getObject(
                {
                    Bucket: this.bucket /* 必须 */,
                    Region: this.region /* 存储桶所在地域，必须字段 */,
                    Key: key /* 必须 */,
                },
                function (err, data) {
                    // console.log(err || data.Body);
                    if (data.Body) {
                        resolve(data.Body);
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };

    deleteObject = async (key) => {
        return new Promise((resolve, reject) => {
            this.cos.deleteObject(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Key: key,
                },
                (err, data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };

    getObjectUrlBlob = async (key) => {
        return new Promise((resolve, reject) => {
            this.cos.getObjectUrl(
                {
                    Bucket: this.bucket,
                    Region: this.region,
                    Key: key,
                    Sign: true,
                    Expires: 3600,
                },
                (err, data) => {
                    if (data.Url) {
                        const xhr = new XMLHttpRequest();
                        xhr.open("get", data.Url, true);
                        xhr.responseType = "blob";
                        xhr.onload = (e) => {
                            const res = e.target;
                            if (res.status == 200) {
                                resolve(URL.createObjectURL(res.response));
                            }
                        };
                        xhr.send();
                    } else {
                        reject(err);
                    }
                }
            );
        });
    };
}

export default new cosService();
